@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: inline-block;
  position: relative;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 98%;
    height: 98%;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  &_blue {
    div {
      border-color: $blue transparent transparent transparent;
    }
  }

  &_size_large {
    width: 64px;
    height: 64px;

    div {
      border-width: 8px;
    }
  }
  &_size_medium {
    width: 40px;
    height: 40px;

    div {
      border-width: 4px;
    }
  }
  &_size_small {
    width: 22px;
    height: 22px;

    div {
      border-width: 3px;
    }
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}
