@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  background: $bg-light;

  & main {
    padding-top: 0 !important;
    overflow: visible !important;
  }
}

.content {
  position: relative;
  padding-bottom: $unit * 6;

  @media screen and (max-width: $sm-max-width) {
    padding-bottom: 60px;
  }

  &__row {
    justify-content: space-between;

    @media screen and (max-width: $sm-max-width) {
      flex-direction: column;
    }
  }

  &__form {
    max-width: 740px;

    @media screen and (max-width: $sm-max-width) {
      padding-right: 0 !important;
    }
  }

  &__faq {
    max-width: 660px;
  }

  &__submit {
    padding-top: 50px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding: $unit * 2 $unit * 2;
    background: $bg-light;
  }
}
