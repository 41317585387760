@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

@import "../../../scss/consts";

.selector {
  justify-content: space-between;
  padding-bottom: 25px;
  overflow: auto;

  &__item {
    white-space: nowrap;
    padding: 0 $unit !important;

    &_hasData {
      cursor: pointer;
      font-weight: bold !important;
    }
  }
}

.wrapperRef {
  padding-right: 10px;
}
