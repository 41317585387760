@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.menu {
  background: $white;
  padding: 12px 0;
  border-radius: 10px;
  width: 100%;

  &__item {
    cursor: pointer;
    padding: 0 20px;
    height:  56px;
    background: transparent;
    transition: all $transition-time;

    &:hover {
      background: $color-purple-bg;
    }

    &:active {
      background: $color-bg-dark;
    }
  }

  &__itemIcon {
    width: 32px;
  }

  &__itemText {
    padding-top: 2px;
  }

  &__itemArrow {
    opacity: .3;
    transition: all $transition-time;
  }
}

.modal {
  background-color: white;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(134, 134, 155, .3);
}
