@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

$height: 160px;
$width: 160px;

.content {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $width;
  height: $height;
  transform: translate(calc($width / -2), calc($height / -1.9));
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expired {
  * {
    @include text_m();
    font-weight: 200;
    line-height: 20px;
  }

  b {
    font-weight: 500;
  }
}

.remain {
  color: $blue;
  @include text_sm();
  font-weight: 200;

  &_spent, &_spent * {
    color: $header;
  }

  b {
    @include title_l();
    line-height: 30px;
    font-weight: 500;
  }
}

.volume {
  @include text_sm();
  color: $text;
  font-weight: 200;

  b {
    color: $header;
    font-weight: 600;
  }
}

.separator {
  height: 2px;
  margin: 10px auto;
  background: $bg-light;
  width: 100px;
}

.until {
  @include text_sm();
  color: $text;
  font-weight: 200;

  b {
    color: $purple;
    display: block;
    white-space: nowrap;
    font-weight: 500;
  }
}

.spent {
  font-weight: 500;
  @include text_m();
}
