@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  width: 150px;
  min-height: 140px;
  border-radius: 13px;
  cursor: pointer;
  background: $bg-light;
  padding: 20px 22px;
  display: flex;
  flex-direction: column;
  color: $header;

  &_selected {
    background: $soft-orange-grad;
    color: $white;
  }

  &_inline {
    flex-direction: row;
    min-height: 50px;
    width: 100%;

    & > div:first-child {
      padding: 0 $unit * 2 0 0;
    }
  }

  &__size {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
  }

  &__days {
    font-size: 16px;
    font-weight: 200;
    flex-grow: 1;
  }

  &__price {
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
  }
}
