@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

@import "../../../../scss/colors";

.wrapper {
  color: $header;
  text-align: center;
  width: 100%;
}

.title {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 11px;
}

.info {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 55px;
}

.btn {
  width: 100%;
}
