@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  background: $bg-light;
}

.content {

  &__wrapper {
    max-width: 965px;

    &_no-limit {
      max-width: 100%;
    }
  }

  &__no-limit {
    max-width: $container-max-width;
    margin: 0 auto;
  }
}