@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.packages {
  padding-top: 40px;
  padding-bottom: 40px;
}

.cards {
  overflow-x: auto;
  margin: $unit * 2 $unit * -2 0;
  padding: 0 $unit * 2;

  & > div:last-child {
    padding-right: $unit * 2;
  }
}
