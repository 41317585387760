@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.intro {
  padding-top: 70px;
  padding-bottom: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  @media screen and (max-width: $sm-max-width) {
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__title {
    position: relative;

    @media screen and (max-width: $sm-max-width) {
      @include title_l;
    }
  }
}
