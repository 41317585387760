@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  & main {
    padding-top: 0 !important;
  }
}

.content {
  background: $white;
  position: relative;
  padding-bottom: $unit * 6;

  &__col {
    align-items: flex-start;
  }

  &__info {
    flex-direction: column;

    @media screen and (max-width: $sm-max-width) {
      padding-right: 0 !important;
    }
  }

  &__countries {
    padding-top: 40px;

    @media screen and (max-width: $sm-max-width) {
      padding-top: 0px;
      width: 100%;
    }
  }
}
