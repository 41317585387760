@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.content {
  max-width: 740px;

  &__total {
    padding: 30px 40px 50px 0;

    @media screen and (max-width: $sm-max-width) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__lockIcon {
    padding-bottom: 4px;
  }

  .agreement{
    color: $additional-text;
    margin-left: 20px;
    margin-top: 10px;
  }
}
