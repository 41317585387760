@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.table {

  &__row {
    padding: $unit * 2 0;
    border-bottom: 1px solid $bg-light;

    @media screen and (max-width: $sm-max-width) {
      border-bottom: none;
    }
  }

  &__date {
    width: 140px;

    @media screen and (max-width: $sm-max-width) {
      width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__up {
    width: 130px;

    @media screen and (max-width: $sm-max-width) {
      width: 60px;
    }
  }

  &__down {
    width: 130px;

    @media screen and (max-width: $sm-max-width) {
      width: 60px;
    }
  }
}
