@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.packages {
  padding-top: 50px;

  &__description {
    max-width: 380px;
    text-align: center;
  }

  &__form {
    padding-top: $unit * 2;
    width: 600px;
    padding-bottom: $unit * 10;
  }

  &__cardsWrapper {
    width: 100%;
    overflow: auto;
    padding: 40px $unit * 2;
  }

  &__cards {
    margin: 0 auto;
    min-height: 320px;
  }

  &__cardWrapper {
    &:not(:last-child) {
      padding-right: 20px;
    }
  }
}
