@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.item {
  position: relative;

  &__head {
    height: 60px;
    border-radius: 16px;
    background: $color-bg-dark;
    padding: 20px 35px;
    cursor: pointer;
    transition: all $transition-time * 4;

    @media screen and (max-width: $sm-max-width) {
      padding: 17px 20px;
    }
  }

  &_active &__head {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media screen and (max-width: $sm-max-width) {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  &__arrow {
    transition: all $transition-time * 2;
  }

  &_active &__arrow {
    transform: rotate(180deg);
  }

  &__content {
    border: none;
    background: transparent;
    overflow: hidden;
    transition: max-height $transition-time * 2,
    padding $transition-time * 2,
    background $transition-time * 2,
    opacity $transition-time * 2;
    max-height: 0;
    padding: 0px 35px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    opacity: 0;

    @media screen and (max-width: $sm-max-width) {
      padding: 0px 20px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &_active &__content {
    border-top: 1px solid $white;
    background: $color-bg-dark;
    height: auto;
    max-height: 600px;
    padding: 20px 35px;
    opacity: 1;

    @media screen and (max-width: $sm-max-width) {
      padding: 10px 20px;
      background: transparent;
      border: none !important;
    }
  }

  &__contentWrapper {
    overflow: auto;
    padding: 10px 0;
  }
}
