@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.links {
  padding: 30px $unit * 2;
  flex-wrap: wrap;

  @media screen and (max-width: $sm-max-width) {
    flex-direction: column;
  }
}

a {
  color: $color-dark-blue;
}

div.link {
  padding-right: 40px !important;

  @media screen and (max-width: $sm-max-width) {
    padding-right: 0 !important;
    margin: 10px auto;
    font-size: 18px;
    font-weight: 600;
  }
}
