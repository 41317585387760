@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  background: $white;

  & main {
    padding-top: 0 !important;
  }
}

.content {
  position: relative;
  padding-bottom: $unit * 6;
  padding-top: $unit * 4;
}
