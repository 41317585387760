@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.info {
  min-width: 500px;

  @media screen and (max-width: $sm-max-width) {
    width: 100%;
    min-width: auto;
  }

  &__title {
    padding-bottom: 16px;

    @media screen and (max-width: $sm-max-width) {
      padding-bottom: 6px;
      font-size: 28px;
      padding-top: $unit * 2;
    }
  }

  &__subtitle {
    @include text_l();
    color: $additional-text;
    max-width: 400px;
    line-height: 25px;
    font-weight: 200;

    @media screen and (max-width: $sm-max-width) {
      @include text_m();
    }
  }
}
