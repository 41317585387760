@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  background: $bg-light;
  padding: 30px 25px;
  border-radius: 16px;
  width: 250px;
  height: 200px;
  transition: background $transition-time;

  @media screen and (max-width: $sm-max-width) {
    width: auto;
  }

  &:hover {
    background-color: #F0F0FA;
  }

  &__number {
    font-size: 30px;
    color: $text;
    font-weight: 300;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
  }

  &__desc {
    color: $additional-text;
    font-size: 16px;
    line-height: 22px;
  }
}
