@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.btn {
  height: $btn-height;
  border-radius: $btn-border-radius;
  padding: 0px 50px 0px;
  @include text_m;
  @include buttonStyles;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;

  &_small {
    height: $btn-small-height;
  }

  &_loading {
    pointer-events: none;
  }

  &__theme {
    &_white {
      color: $header;
      background: $white;
      border-color: transparent;

      &:active {
        box-shadow: $btn-primary-active-shadow;
      }
    }

    &_outline {
      color: $blue;
      background: $white;
      border: 1px solid $blue;

      &:active {
        box-shadow: $btn-primary-active-shadow;
      }
    }

    &_primary {
      color: $btn-primary-color;
      background: $btn-primary-bg;

      &:active {
        box-shadow: $btn-primary-active-shadow;
      }

      &:hover {
        background: $btn-primary-active-bg;
      }
    }

    &_secondary-dark {
      color: $btn-secondary-dark-color;
      background: $btn-secondary-dark-bg;

      &:active {
        box-shadow: $btn-secondary-dark-active-shadow;
      }

      &:hover {
        background: $btn-secondary-dark-active-bg;
      }
    }

    &_secondary-light {
      color: $btn-secondary-light-color;
      background: $btn-secondary-light-bg;

      &:active {
        box-shadow: $btn-secondary-light-active-shadow;
      }

      &:hover {
        background: $btn-secondary-light-active-bg;
      }
    }

    &_flat-white {
      color: $btn-secondary-light-color;
      background: $white;

      &:active {
        box-shadow: $white;
      }

      &:hover {
        background: $white;
      }
    }

    &_accent {
      color: $btn-accent-color;
      background: $btn-accent-bg;

      &:active {
        box-shadow: $btn-accent-active-shadow;
      }

      &:hover {
        background: $btn-accent-active-bg;
      }
    }
  }

  &_disabled {
    opacity: .4;
    pointer-events: none;
  }

  &_fluid {
    width: 100%;
  }

  &_smallPadding {
    padding-right: 16px;
    padding-left: 16px;
  }

  &__icon {
    height: 100%;
    top: 0;
    position: absolute;
    
    &_left {
      left: $btn-icon-offset;
    }

    &_right {
      padding-left: $unit * 2;
      right: $btn-icon-offset;
    }

    &_static {
      position: static;
    }
  }

  &__loader {
    padding-left: $unit * 2;
  }

  &__text {
    padding-top: 2px;
  }
}
