@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.auth {
  width: 160px;
  height: 50px;
  position: relative;

  &__login {
    position: static;

    &_white {
      position: static;
      
      a {
        color: $white;
      }
    }
  }

  &__user {
    padding: 5px;
    height: 50px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    background: $bg-light;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(134, 134, 155, .3);
  }

  &__userName {
    padding: 0 4px 0 14px;
    width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__userAvatar {
    position: relative;
    width: 37px;
    height: 37px;
    border-radius: 100%;
    background: linear-gradient(258.76deg, #4F80FF 5.73%, #3251F9 87.4%);

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__menu {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-radius: 25px;
    height: 50px;
    overflow: hidden;
    transition: all $transition-time;
    padding-bottom: 0;

    &_open {
      background: $blue;
      padding-top: 56px;
      padding-bottom: 6px;
      height: auto;
    }
  }

  &__menuItem {
    height: 40px;
    line-height: 40px;
    background: transparent;
    transition: all $transition-time;
    cursor: pointer;
    color: $white;

    &:hover {
      background: $color-dark-blue;
    }
  }
}
