@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.modal {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: $bg-light;
  overflow-y: auto;

  &__topBg {
    height: 220px;
    background: $grey;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 1px);
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__title {
    color: $header;
    padding-top: $unit * 2;
    padding-bottom: $unit * 4;
    margin: 70px auto 0 auto;
  }
}

.info {
  display: block;
  margin: 16px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 0 15px;
  color: #6C757B;

  > a {
    color: #405EFF;
  }
}

.infoText {
  margin-bottom: 20px;
}

.otherAuth {
  margin: 50px auto 0;
  text-align: center;
  flex-direction: column;
  width: 100%;

  > div {
    padding: 0 !important;
  }

  &__title {
    font-weight: 500;
  }

  &__btnText {
    padding-top: 2px;
  }

  &__button {
    margin-bottom: $unit * 2;
    width: 100%;
    height: 45px;

    @media screen and (max-width: $sm-max-width) {
      font-size: 18px;
      padding-right: 14px;
      padding-left: 14px;
      margin-bottom: 10px;
    }
  }
}


.barBtn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    cursor: pointer;
    color: #405EFF;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    &:first-child {
      margin-right: 32px;
    }
  }
}
