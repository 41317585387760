@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.modal {
  width: 780px;
  max-width: 100%;
  height: 550px;
  background: linear-gradient(90deg, #405EFF 0%, #9440FF 273.87%);
  border-radius: 16px;
  box-shadow: 0px 10px 30px rgba(227, 234, 255, 0.9);
  overflow: hidden;

  &__wrapper {
    height: 100%;
  }
}

.img {

}

.info {
  display: block;
  text-align: center;
  margin: 16px 0 80px 0;
  color: #6C757B;
}

.side_left {
  min-width: 270px;
  width: 270px;
  color: $grey;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 60px;
  padding-right: $unit * 4 !important;

  h3 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 200;
    font-family: $secondary-font;
    padding-bottom: 20px;
  }
}

.close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  right: -44px;
  z-index: 10;
  cursor: pointer;
}

.side {
  position: relative;

  &_right {
    background: $grey;
    align-items: flex-start;
    padding: 0 45px 0 70px;
    flex-direction: column;
    width: 550px;
    max-width: 550px;
    height: 100%;
  }

  &__title {
    padding: 84px 0 24px 0;
    font-style: normal;
    margin: 0 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  &__title_small {
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 20px;
  }
}

.img {
  z-index: 1;
  position: absolute;
  right: -19px;
}

.terms {
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  color: $text;
  max-width: 280px;
  padding-bottom: $unit * 2;
}

.otherAuth {
  padding-top: $unit * 4;
  align-self: flex-end;
  flex-direction: column;
  width: 100%;

  > div {
    padding: 0 !important;
  }

  button {
    width: 100%;
    height: 52px;
    line-height: 12px;
    margin-bottom: $unit * 2;
    background-color: $white !important;
  }
}

.authBtn {
  > button {
    > div {
      justify-content: left;
    }

  }
}

.fixedPositionCenter {
  > div {
    > div {
      &:nth-child(2) {
        margin-left: 100px;
      }
    }
  }

}


.barBtn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    cursor: pointer;
    color: #405EFF;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    &:first-child {
      margin-right: 32px;
    }
  }
}
