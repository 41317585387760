@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  box-shadow: 0px 10px 30px rgba(227, 234, 255, 0.9);
  border-radius: 16px;
  padding: 20px 20px 30px 20px;
  border: 1px solid transparent;
  transition: border $transition-time;
  cursor: pointer;
  width: 280px;
  height: 100%;

  &:hover {
    border-color: $blue;
  }

  &__image {
    margin-top: -40px;
    padding-bottom: 26px;
    height: 200px;
  }

  &__title {
    padding-bottom: $unit;
  }

  &__count {
    padding-top: 20px;
    margin-top: auto;
  }
}
