@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  width: 343px;
  height: 173px;
  border-radius: 20px;
  color: $white;
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
  background: $blue-purple-grad-soft;

  @media screen and (max-width: $sm-max-width) {
    width: 100%;
    min-width: 300px;
  }

  &_small {
    width: 120px;
    border-radius: 11px;
    height: 147px;
    padding: 14px 12px;

    @media screen and (max-width: $sm-max-width) {
      min-width: auto;
    }

    & .title {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &_asia {
    background: $pink-grad;
  }
  &_usa {
    background: $purple-grad;
  }

  &_checked {
    &_false::before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      z-index: 3;
      outline: 1px solid red;
      top: 0;
      left: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__count {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 200;
  }

  &__cost {
    font-size: 16px;
  }

  &__price {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 4px;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
}

.check {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;
}
