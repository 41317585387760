@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.content {
  padding-top: $unit * 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.small-cards {
  width: 100%;
  overflow-y: auto;
}
