@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.download {
  padding-top: 200px;

  @media screen and (max-width: $sm-max-width) {
    padding-top: 50px;
  }

  &__content {
    @media screen and (max-width: $sm-max-width) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    max-width: 400px;

    @media screen and (max-width: $sm-max-width) {
      padding-right: 0 !important;
    }
  }

  &__buttons {
    padding-top: 50px;

    @media screen and (max-width: $sm-max-width) {
      padding-top: 24px;
    }

    a {
      @media screen and (max-width: $sm-max-width) {
        margin: 0;
      }
    }

    img {
      @media screen and (max-width: $sm-max-width) {
        max-width: 100%;
      }
    }
  }
}


.image {
  position: relative;
  right: -34px;

  @media screen and (max-width: $sm-max-width) {
    right: auto;
  }

  &__phone1 {
    margin-right: -30px;

    @media screen and (max-width: $sm-max-width) {
      margin-right: 0;
    }
  }

  img {
    @media screen and (max-width: $sm-max-width) {
      max-width: 100%;
    }
  }
}
