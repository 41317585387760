@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.form {

  @media screen and (max-width: $sm-max-width) {
    padding-bottom: 30px;
  }

  &__card {
    padding-bottom: 30px;

    @media screen and (max-width: $sm-max-width) {
      padding-bottom: 10px;
    }
  }

  &__btnGroup {
    padding-bottom: 30px;

    @media screen and (max-width: $sm-max-width) {
      padding-bottom: 10px;
    }
  }

  &__select {
    max-width: 370px;

    @media screen and (max-width: $sm-max-width) {
      max-width: 100%;
    }
  }

  &__submit {
    padding-top: 50px;
  }

  &__selectControl {
    border-radius: 8px;
    border: 1px solid transparent;

    &_selected {
      border: 1px solid $blue;
    }
  }
}
