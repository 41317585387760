@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";


.qr {
  position: absolute;
  right: -130px;
  top: -300px;
  width: 840px;
  min-width: 840px;
  height: 900px;

  @media screen and (max-width: $desktop-max-width) {
    transform: scale(.84);
    right: -150px;
  }

  @media screen and (max-width: $tablet-max-width) {
    margin-top: -100px;
    top: auto;
    right: auto;
    left: 50%;
    transform: scale(1) translateX(-50%);
  }

  &__grad {
    width: 800px;
  }

  &__phone {
    position: absolute;
    bottom: 83px;
    right: 41px;
    width: 800px;
  }

  &__qrBlock {
    position: absolute;
    top: 320px;
    left: 266px;
    width: 200px;
  }

  &__qrImage {
    width: 200px;
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 16px;
  }
}
