@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.intro {
  padding-top: 70px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  @media screen and (max-width: $sm-max-width) {
    background: $blue-purple-grad-soft;
    height: 420px;
  }

  &::after {
    content: '';
    height: 80px;
    width: calc(100% + 100px);
    background: $blue-purple-grad-soft;
    bottom: -5px;
    left: -50px;
    position: absolute;
    border-radius: 100%;
    display: none;

    @media screen and (max-width: $sm-max-width) {
      display: block;
    }

    @media screen and (max-width: 450px) {
      bottom: -9px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    display: none;

    @media screen and (max-width: $sm-max-width) {
      display: block;
    }
  }

  &__title {
    position: relative;

    @media screen and (max-width: $sm-max-width) {
      @include title_l;
      color: $white;
    }
  }

  &__subtitle {
    max-width: 450px;
    display: block;

    @media screen and (max-width: $sm-max-width) {
      display: none;
    }
  }

  &__qr {
    top: -30px;
    z-index: 1;
    position: relative;
  }

  &__qrPhone {
    position: absolute;
    top: -124px;
    left: 50%;
    transform: translateX(-46%);
    width: 700px;
  }

  &__qrImage {
    position: absolute;
    top: 110px;
    left: 50%;
    width: 200px;
    transform: translateX(-50%);
  }
}
