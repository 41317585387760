@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.wrapperContent {
  font-size: 18px;
  line-height: 21.6px;
  margin-bottom: 20px;
  font-weight: 300;

  > h2 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
    color: #161f24;
    margin: 0 0 12px 0;
  }

  > p {
    padding-bottom: 12px;
    color: #6c757b;
  }

  > ul {
    color: #6c757b;
    list-style-type: none;
    padding: 0;
    margin: 18px 0;

    > li {
      padding-bottom: 12px;
    }
  }

}

.faqCollapse {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 300;
  color: #161f24;

  > h3 {
    padding: 12px 0;
  }
}

.contact {
  color: #161f24;

  > h3 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
    padding-bottom: 12px;
  }

  > h5 {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 300;
    margin: 0;
    padding-bottom: 12px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  > p {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 300;
    margin: 0;
    padding-bottom: 12px;
  }
}
