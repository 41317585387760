@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.intro {
  max-height: 700px;
  margin-bottom: 70px;

  @media screen and (max-width: $sm-max-width) {
    max-height: none;
    margin-bottom: 0;
  }

  &__wrapper {
    height: 100%;

    @media screen and (max-width: $sm-max-width) {
      flex-direction: column-reverse;
      height: auto;
      text-align: center;
    }
  }

  &__title {
    width: 580px;

    @media screen and (max-width: $tablet-max-width) {
      width: auto;
    }

    @media screen and (max-width: $sm-max-width) {
      font-size: 18px;
      line-height: 21.6px;
      width: 100%;
      margin-bottom: 14px;
    }
  }

  &__image {
    display: block;

    &Main {
      margin-top: 0px;
      width: 800px;

      @media screen and (max-width: $tablet-max-width) {
        max-width: calc(100% + 200px);
        width: auto;
      }

      @media screen and (max-width: $sm-max-width) {
        max-width: 100%;
      }
    }
  }

  &__buttons {
    @media screen and (max-width: $sm-max-width) {
      flex-direction: column;
    }

    a {
      @media screen and (max-width: $sm-max-width) {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }

    button {
      @media screen and (max-width: $sm-max-width) {
        width: 100%;
      }
    }

    > div {
      @media screen and (max-width: $sm-max-width) {
        padding-right: 0 !important;
      }
    }
  }
}
