@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.content {
  &__listCol {
    max-width: 420px;

    @media screen and (max-width: $sm-max-width) {
      max-width: 100%;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__listItem {
    padding: 36px 32px;
    margin-bottom: $unit * 2;
    background: $bg-light;
    border-radius: 20px;

    @media screen and (max-width: $sm-max-width) {
      padding: 22px 20px;
    }
  }
  
  &__imgCol {
    position: relative;
    height: 570px;

    @media screen and (max-width: $tablet-max-width) {
      height: 610px;
    }
  }
}
