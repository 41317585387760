@mixin text($size) {
  font-size: $size;
  line-height: calc($size * 1.2);
}

@mixin title($size) {
  @include text($size);
  font-weight: 600;
}

@mixin title_xxl {
  @include title($fs-title-xxl);
}

@mixin title_xl {
  @include title($fs-title-xl);
}

@mixin title_l {
  @include title($fs-title-l);
}

@mixin title_m {
  @include title($fs-title-m);
}

@mixin title_sm {
  @include title($fs-title-sm);
}

@mixin text_xxl {
  @include text($fs-text-xxl);
}

@mixin text_xl {
  @include text($fs-text-xl);
}

@mixin text_l {
  @include text($fs-text-l);
}

@mixin text_m {
  @include text($fs-text-m);
}

@mixin text_sm {
  @include text($fs-text-sm);
}

@mixin text_ssm {
  @include text($fs-text-ssm);
}

// button
@mixin buttonStyles {
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: all $transition-time;
  border: transparent;
}
