@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.circles {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  position: absolute;
  border-radius: 100%;

  &_color {
    &_purple {
      background: $purple
    }
    &_blue {
      background: $blue
    }
    &_orange {
      background: $orange
    }
    &_orange-grad {
      background: $oragne-grad
    }
    &_gray {
      background: $text;
    }
    &_red {
      background: $color-red;
    }
  }
}
