@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.breads {
  padding-top: 50px;
  padding-bottom: 30px;

  @media screen and (max-width: $sm-max-width) {
    display: none;
  }
}

.item {
  &:not(:first-child)::before {
    content: '/';
    display: inline-block;
    padding-right: $unit * 2;
    color: $text;
  }

  & > * {
    @include text_m();
    color: $text;
    font-weight: 300;
  }

  &:last-child {
    & > *, &::before {
      color: $header;
    }

    & > a:hover {
      color: $blue;
    }
  }

  &_white {
    & > *, &::before {
      color: $white;
      opacity: .6;
    }

    &:not(:first-child) {
      &::before {
        color: $white;
        opacity: .6;
      }
    }

    &:last-child {
      & > * {
        opacity: 1;
        color: $white;
      }
    }

    & > a:hover {
      opacity: 1;
    }
  }
}
