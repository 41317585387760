@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.layout {
  min-height: 100vh;
  // background: white;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;

  &_bg {
    &_gray {
      background: $bg-light;
    }

    &_purple-grad {
      background: $blue-purple-grad-soft;
    }
  }

  .main {
    flex-grow: 1;
    padding-top: 80px;
    overflow: hidden;

    @media screen and (max-width: $sm-max-width) {
      flex-direction: column;
      display: flex;
      padding-top: 63px;
    }
  }
}
