@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  background: $bg-light;

  & main {
    padding-top: 0 !important;
  }
}

.content {
  position: relative;
  padding-bottom: $unit * 6;
  padding-top: $unit * 4;

  &__col {
    align-items: flex-start;
  }

  &__info {
    flex-direction: column;

    @media screen and (max-width: $sm-max-width) {
      padding-right: 0 !important;
    }
  }

  &__countries {
    padding-top: 40px;

    @media screen and (max-width: $sm-max-width) {
      padding-top: 0px;
      width: 100%;
    }
  }
}

.menu {
  max-width: 700px;
  padding-bottom: 24px;
  margin: 0 auto;

  .logoWrapper{
    margin-bottom: -10px;
    height: 377px;
    border-radius: 10px;
    background-color: white;

    .logoFlexWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .logo {
        display: grid;
        justify-items: center;

        .version{
          padding-top: 9px;
        }
      }
    }
  }
}
.yolla {
  width: 100px;
  height: 50px;
  background-image: url("../../assets/icons/yollaLogo.svg");
  background-position: center;
  background-repeat: no-repeat;
}
