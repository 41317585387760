body * {
  box-sizing: border-box;
}

html {
  font-size: $main-font-size;
  font-family: $main-font;
  // line-height: $main-font-size + $unit;
}

html {
  min-height: 100vh;
  overflow-y: auto;
}

body, #root {
  min-height: 100vh;
}

h1, h2, h3, p {
  margin: 0;
}

a {
  text-decoration: none;
}

.ReactModal__Content {
  background: none !important;

  @media screen and (max-width: $sm-max-width) {
    height: 100%;
  }
}
.ReactModal__Body--open #root {
  filter: blur(6px);
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
