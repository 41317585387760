@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.screen {
  background: $white;

  main {
    padding-bottom: 40px;
  }

  &__cards {
    overflow-x: auto;
  }

  &__card {
    @media screen and (max-width: $sm-max-width) {
      padding-bottom: $unit * 2;
    }
  }

  &__btns {
    padding-top: 30px;
  }
}
