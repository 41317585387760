@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.text {
  font-weight: $fs-text-weight;

  &_color {
    &_blue {
      color: $blue;
    }

    &_lightblue {
      color: $color-light-blue;
    }

    &_black {
      color: $header;
    }

    &_gray {
      color: $text;
    }
  }

  &_size {
    &_xxl {
      @include text_xxl;
    }

    &_xl {
      @include text_xl;

      @media screen and (max-width: $sm-max-width) {
        font-size: calc($fs-text-xl / 1.2);
        line-height: $fs-text-xl;
      }
    }

    &_l {
      @include text_l;
    }

    &_m {
      @include text_m;

      @media screen and (max-width: $sm-max-width) {
        font-size: calc($fs-text-m / 1.2);
        line-height: $fs-text-m;
      }
    }

    &_sm {
      @include text_sm;
    }
  }

  &_weight {
    &_medium {
      font-weight: 500;
    }

    &_thin {
      font-weight: 300;
    }

    &_bold {
      font-weight: 700;
    }
  }
}
