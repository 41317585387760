@import './colors.scss';

// global
$main-font: 'SF Compact Display';
$secondary-font: 'PT Sans', sans-serif;
$container-max-width: 1200px;
$main-font-size: 16px;

// button
$btn-primary-bg: $blue-purple-grad-soft;
$btn-primary-color: $white;
$btn-primary-active-bg: $blue-purple-grad;
$btn-primary-active-shadow:  0px 5px 0px #9897FF;

$btn-secondary-dark-bg: $color-dark-blue;
$btn-secondary-dark-color: $white;
$btn-secondary-dark-active-bg: $color-dark-blue;
$btn-secondary-dark-active-shadow:  0px 5px 0px #9897FF;

$btn-secondary-white-bg: $white;

$btn-secondary-light-bg: $bg-light;
$btn-secondary-light-color: $header;
$btn-secondary-light-active-bg: rgb(221, 221, 252);
$btn-secondary-light-active-shadow:  0px 5px 0px rgba(221, 221, 252, .6);

$btn-accent-bg: $soft-orange-grad;
$btn-accent-color: $white;
$btn-accent-active-bg: $oragne-grad;
$btn-accent-active-shadow: $orange;

$btn-height: 60px;
$btn-small-height: 45px;
$btn-active-shadow: 0px 5px 0px #9897FF;
$btn-border-radius: 8px;
$btn-font-size: 16px;
$btn-icon-offset: 16px;

// fonts
$fs-title-xxl: $unit * 8;
$fs-title-xl: $unit * 8;
$fs-title-l: $unit * 6;
$fs-title-m: $unit * 4;
$fs-title-sm: 22px;

$fs-text-xxl: $unit * 6;
$fs-text-xl: $unit * 4;
$fs-text-l: $unit * 3;
$fs-text-m: 16px;
$fs-text-sm: 14px;
$fs-text-ssm: $unit * 2;
$fs-text-weight: 300;

// animations
$transition-time: .3s;

// input
$input-border-color: #D0D8DD;
$input-focused-border-color: $header;
$input-placeholder-color: $text;
$input-color: $header;
$input-error-color: $color-red;

// links
$link-color: $blue;
