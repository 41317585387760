@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.cards {
  padding-top: $unit * 6;

  @media screen and (max-width: $sm-max-width) {
    flex-direction: column;
    width: 100%;
    padding: $unit * 3 0 0;
  }

  & > div {
    @media screen and (max-width: $sm-max-width) {
      width: 100%;
      padding-right: 0 !important;
      padding-bottom: $unit * 2;
    }
  }
}

.card {
  @media screen and (max-width: $sm-max-width) {
    width: 100%;
  }
}
