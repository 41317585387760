@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: $unit * 4;
  z-index: 2;
}

.backLink {
  color: $white;
  
  &_dark {
    color: $blue;
  }
}

.logo {
  width: 120px;

  @media screen and (max-width: $sm-max-width) {
    width: 90px;
  }
}

.settings {
  cursor: pointer;
}
