@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.works {
  padding-top: 110px;

  @media screen and (max-width: $sm-max-width) {
    padding-top: 50px;
  }

  &__title {
    padding-bottom: 90px;

    @media screen and (max-width: $sm-max-width) {
      padding-bottom: 36px;
    }
  }

  &__inner {
    position: relative;
    min-height: 552px;

    @media screen and (max-width: $sm-max-width) {
      flex-direction: column;
      align-items: center;
      min-height: auto;
    }
  }

  &__img {
    margin-left: -220px;
    width: 800px;
    position: absolute;
    z-index: -1;
  }

  &__content {
    @media screen and (max-width: $sm-max-width) {
      width: 100%;
      justify-content: center;
    }
  }

  &__cards {
    display: grid;
    gap: 24px;
    max-width: 524px;
    width: 100%;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));

    @media screen and (max-width: $sm-max-width) {
      //justify-items: center;
    }
  }

  &__card {
    display: block;
  }
}
