@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.title {
  font-style: normal;
  font-weight: 500;
  margin: 0;

  &_font_secondary {
    font-family: $secondary-font;
  }

  &_thin {
    font-weight: 300;
  }

  &_size {
    &_xxl {
      @include title_xxl();

      @media screen and (max-width: $sm-max-width) {
        @include title_xl();
      }
    }
    &_xl {
      @include title_xl();

      @media screen and (max-width: $sm-max-width) {
        @include title_l();
      }
    }
    &_l {
      @include title_l();

      @media screen and (max-width: $sm-max-width) {
        @include title_m();
      }
    }
    &_m {
      @include title_m();

      @media screen and (max-width: $sm-max-width) {
        font-size: calc($fs-title-m / 1.2);
        line-height: calc($fs-title-m / 1.2);
      }
    }
  }
}
