@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.countries {
  box-shadow: 0px 10px 30px rgba(227, 234, 255, 0.9);
  border-radius: 16px;
  width: 487px;
  height: 350px;
  padding: 40px;

  @media screen and (max-width: $sm-max-width) {
    box-shadow: none;
    padding: 40px 0 0;
    width: 100%;
    height: auto;
  }

  &__title {
    @include title_m;
    font-weight: 500;
    padding-left: 20px;
    padding-bottom: $unit * 2;

    @media screen and (max-width: $sm-max-width) {
      font-size: 20px;
    }
  }

  &__list {
    max-height: calc(100% - 52px);
    padding: 0 18px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    column-gap: 6px;
    overflow: auto;
  }

  &__row {
    padding: 12px 18px 6px;

    &:not(:last-child) {
      border-bottom: 1px solid #ECECEC;
    }
  }
}

.country {
  display: inline-flex;
  align-items: center;
  padding: 12px 0 6px;

  &__flag {
    margin-right: $unit*2;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__name {
    @include text_sm;
    font-weight: 300;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
