@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.wrapper {
  // min-width: 300px;
  width: 100%;
  padding-bottom: 6px;
  position: relative;
}

.input {
  color: $input-color;
  font-size: 16px;
  line-height: 22px;
  border: none;
  width: 100%;
  outline: none;
  padding: 16px 10px;
  transition: all $transition-time;

  &_theme {
    &_underline {
      border-bottom: 1px solid $input-border-color;
      font-weight: 600;

      &:focus {
        border-bottom: 1px solid $input-focused-border-color;
      }
    }

    &_flat {
      border: 1px solid transparent;
      background: $bg-light;
      border-radius: 10px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }


  &::placeholder {
    font-weight: 400;
    color: $input-placeholder-color;
  }

  &_error {
    border-color: $input-error-color !important;
  }

  &_withSuffix {
    padding-right: 60px;
  }

  &__suffix {
    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    width: 60px;
  }
}

.error {
  color: $color-red;
  font-size: 12px;
  padding-top: 4px;
  height: 16px;
  line-height: 14px;
}

.lockIcon {
  position: absolute;
}

.icon {
  color: #3F3FD3;
  fill: red;
}
