@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.card {
  padding: 30px 45px !important;
  background: $white;
  border-radius: 24px;

  @media screen and (max-width: $sm-max-width) {
    padding: 20px 24px !important;
  }

  &__title {
    padding-bottom: $unit * 2;
  }
}
