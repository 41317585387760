@import "../../../../scss/animations.scss";
@import "../../../../scss/consts.scss";
@import "../../../../scss/fonts.scss";
@import "../../../../scss/colors.scss";
@import "../../../../scss/theme.scss";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/global.scss";

.cards {
  overflow-x: auto;
  padding-left: $unit * 2;
  padding-right: $unit * 2;
  padding-top: $unit * 2;
  padding-bottom: $unit * 4;
  min-height: 180px;
}
