@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  padding: 32px 36px;
  background: $white;
  width: 100%;
  border-radius: 16px;

  @media screen and (max-width: $sm-max-width) {
    padding: 14px 20px;
  }

  &__sim {
    height: 54px;
    width: 54px;
    border-radius: 100%;
    margin-right: 14px;
    overflow: hidden;
    position: relative;

    &_icon {
      background: $oragne-grad;
    }
  }

  &__price {
    padding: 0 $unit 0 $unit * 2;
    white-space: nowrap;

    @media screen and (max-width: $sm-max-width) {
      align-items: flex-start;
      padding: $unit * 3 $unit 0 $unit * 2;
    }
  }

  &__desc {
    padding-top: $unit * 2;
  }

  &__customIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}
