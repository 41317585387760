@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

@mixin justify {
  &_justify {
    &_start {
      justify-content: flex-start;
    }
    &_center {
      justify-content: center;
    }
    &_end {
      justify-content: flex-end;
    }

    &_spaceBetween {
      justify-content: space-between;
    }
  }
}

@mixin align {
  &_align {
    &_start {
      align-items: flex-start;
    }
    &_center {
      align-items: center;
    }
    &_end {
      align-items: flex-end;
    }
  }
}

@mixin direction {
  flex-direction: row;

  &_direction {
    &_column {
      flex-direction: column;
    }
  }
}

.row {
  display: flex;
  padding-bottom: $unit * 2;
  @include justify;
  @include direction;
  @include align;

  &_wrap {
    flex-wrap: wrap;
  }

  &_no-gutter {
    padding-bottom: 0;
  }

  &_grow {
    flex-grow: 1;
  }
}

.col {
  display: flex;
  @include justify;
  @include direction;
  @include align;

  &_grow {
    flex-grow: 1;
  }

  &_no_gutter {
    padding-right: 0 !important;
  }

  &:not(:last-child) {
    padding-right: $unit * 2;
  }
}

.container {
  max-width: $container-max-width;
  padding: 0 $unit * 2;
  width: 100%;

  &_main {
    margin: 0 auto;
  }
}
