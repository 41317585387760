@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.landing {
  padding: 30px 0;

  &__logo {
    padding-bottom: 20px;
  }

  &__description {
    padding-bottom: 24px;
    text-align: center;
  }

  .image{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: -20px;
  }
  &__blueText {
    color: $blue;
  }
}
