@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-ultralight-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-ultralight-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-thin-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-thin-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-light-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-medium-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-semibold-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-bold-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-heavy-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-heavy-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'SF Compact Display';
    src: url('../fonts/sf-compact-display-black-webfont.woff2') format('woff2'),
        url('../fonts/sf-compact-display-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@200;400;700&display=swap');
