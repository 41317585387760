@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.intro {
  padding-top: 70px;
  background-image: $blue-purple-grad-soft;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  @media screen and (max-width: $sm-max-width) {
    height: 230px;
  }

  &_asia {
    background-image: $pink-grad;
  }

  &__title {
    color: $white;
    position: relative;

    @media screen and (max-width: $sm-max-width) {
      @include title_l;
    }
  }

  &__img {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 550px;
    height: 399px;

    @media screen and (max-width: $sm-max-width) {
      left: 65%;
      width: 240px;
      height: 160px;
    }
  }

  &__waves {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    min-width: 100%;
    min-height: 800px;

    @media screen and (max-width: $desktop-max-width) {
      height: 800px;
      min-height: auto;
      top: 250px;
    }

    @media screen and (max-width: $sm-max-width) {
      top: 130px;
      height: 300px;
    }
  }

  &__breads {
    position: relative;
  }
}
