@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.widget {
  margin-bottom: 50px;

  @media screen and (max-width: $sm-max-width) {
    margin-bottom: 25px;
  }

  &__wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
