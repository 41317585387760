@import "../../../scss/animations.scss";
@import "../../../scss/consts.scss";
@import "../../../scss/fonts.scss";
@import "../../../scss/colors.scss";
@import "../../../scss/theme.scss";
@import "../../../scss/mixins.scss";
@import "../../../scss/global.scss";

.button {
  width: 64px;
  height: 64px;

  border-radius: 100%;
  box-shadow: none;
  outline: none;
  border: 1px solid $header;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @include buttonStyles;

  &_theme {
    &_primary {
      background: $btn-primary-bg;
      color: $btn-primary-color;

      &:active {
        background: $btn-primary-active-bg;
        box-shadow: $btn-primary-active-shadow;
      }
    }

    &_gray {
      background: $btn-secondary-light-bg;
      color: $btn-secondary-light-color;

      &:hover {
        background: $btn-secondary-light-active-bg;
        box-shadow: $btn-secondary-light-active-shadow;
      }
    }

    &_white {
      background: $btn-secondary-white-bg;
      color: $btn-secondary-light-color;
    }
  }

  &_disabled {
    opacity: .5;
    pointer-events: none;
  }
}
