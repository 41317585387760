$orange: #FF8C22;
$bg-light: #EFF4F7;
$color-bg-dark: #DEE9EF;
$color-purple-bg: #F9F9FF;
$color-light-blue: #67B6FF;
$blue: #415FFF;
$color-dark-blue: #3F3FD3;
$purple: #AB6BFF;
$dark-pink: #DD4AFF;
$color-red: #FF4D00;
$blue-purple-grad-soft: linear-gradient(270deg, #9440FF 0%, #405EFF 100%);
$blue-purple-grad: linear-gradient(270deg, #AD00FF 0%, #0028FF 100%);
$oragne-grad: linear-gradient(149.25deg, $color-red 3.27%, #FF9900 97.97%);
$purple-grad: linear-gradient(106.82deg, $purple 7.85%, #CD52FA 89.53%);
$pink-grad: linear-gradient(119.31deg, #DE53FD 11.62%, #FF42CB 89.73%);
$soft-orange-grad: linear-gradient(90deg, $orange 0%, #FFA722 100%);

$loader-grad: linear-gradient(342.38deg, #9440FF 0%, #405EFF 103.72%);
$text: #6C757B;
$additional-text: #86869B;
$header: #161F24;
$white: white;
$grey: #EFF4F7;


$color-test-grad: linear-gradient(270deg, red 0%, orange 100%);
