@import "../../scss/animations.scss";
@import "../../scss/consts.scss";
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";
@import "../../scss/global.scss";

.card {
  width: 383px;
  height: 375px;
  border-radius: 23px;
  background: $bg-light;
  padding: 18px 25px;
  position: relative;

  &_white {
    background: $white;
  }

  &_small {
    width: 318px;
    height: 351px;

    .usage, .usage * {
      @include text_ssm();
    }

    .icon {
      width: 23px;
    }

    .title {
      @include title_sm();
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    height: 100%;
  }
  
  & path {
    stroke: inherit !important;
  }
}

.title {
  @include title_m();
}

.footer {
  justify-content: space-between;
}

.chart {
  flex-grow: 1;
  position: relative;
}

.usage {
  @include text_sm();
  font-weight: 200;
  color: $text;

  b {
    color: $header;
    font-weight: 500;
  }
}

.icon {
  display: block;
}

.infoLink {
  border: none !important;
}

.circles {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: 1px solid green;
  z-index: 0;
  top: 0;
  left: 0;
}
